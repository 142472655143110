<template>
  <div>
    <top-nav :showLogo="false" :title="$t('我的订单')"></top-nav>

    <div class="state-cont">
      <div class="state-item" @click="selectState(1)">
        <span :class="{'active' : state == 1}">
          {{ $t('进行中') }}
        </span>
      </div>
      <div class="state-item" @click="selectState(2)">
        <span :class="{'active' : state == 2}">
          {{ $t('已完成') }}
        </span>

      </div>
    </div>

    <div class="order-cont">
      <van-list
          v-model="loading"
          :finished="finished"
          :loading-text="$t('加载中')"
          :finished-text="$t('没有更多了')"
          :offset='50'
          @load="onLoad"
      >
        <div class="order-item" v-for="(item, index) in list" :key="index">
          <div class="info-row" style="padding-bottom: 0.7rem">
            <div class="info-left" style="font-size: 1rem;color: #E7BD71">BUR</div>
            <div class="info-right" style="font-size: 0.86rem;color: #B5904E">
            <span v-if="state == 1">
              {{ $t('进行中') }}
            </span>
              <span v-else>
               {{ $t('已完成') }}
            </span>
            </div>
          </div>
          <div class="info-row">
            <div class="info-left">{{ $t('日期') }}</div>
            <div class="info-right">{{ item.created_at }}</div>
          </div>
          <div class="info-row">
            <div class="info-left">{{ $t('套餐时间') }}</div>
            <div class="info-right" style="font-size: 1rem;color: #E7BD71">{{ item.days }}</div>
          </div>
          <div class="info-row">
            <div class="info-left"> {{ $t('认购金额') }}</div>
            <div class="info-right" style="font-size: 1rem;color: #E7BD71"> {{ item.amount }} BUR</div>
          </div>
          <div class="line"></div>

          <div class="info-row">
            <div class="info-left" style="color:#E7BD71;">
              {{ $t('可用利息') }}
            </div>
          </div>
          <div class="info-row">
            <div class="info-left">{{ $t('基本利息') }}</div>
            <div class="info-right" style="font-size: 1rem;color: #E7BD71"> {{ item.regular }} BUR</div>
          </div>
          <div class="info-row">
            <div class="info-left">{{ $t('浮动利息') }}</div>
            <div class="info-right" style="font-size: 1rem;color: #E7BD71"> {{ item.current }} BUR</div>
          </div>

          <div class="_but" v-if="state == 1 && parseFloat(polymerizeBalanceOf) > parseFloat(item.amount)" @click="onshowExtract(item)">
            {{ $t('提取利息赎回本金') }}
          </div>
        </div>
      </van-list>
    </div>

    <van-popup v-model="showExtract">
      <div class="extract-cont">
        <div class="popup-title">
          {{ $t('提取利息赎回本金') }}
          <img @click="onshowExtract" class="close-icon" src="../../assets/image/popup/close-icon.png">
        </div>
        <div class="tips" v-if="default_flag === false">
          {{ $t('请注意，由于您没满合约期提前提取本金和利息，您的利息将会减少。本金扣除1%') }}
        </div>
        <div class="info-cont">
          <div class="info-item">
            <div class="key" style="color: #B5904E">
              {{ $t('提取利息') }}
            </div>
          </div>
          <div class="info-item">
            <div class="key"> {{ $t('浮动利息') }}</div>
            <div class="value">{{current}} BUR</div>
          </div>
          <div class="info-item">
            <div class="key" style="color: #B5904E;padding-top: 0.8rem">
              {{ $t('赎回本金') }}
            </div>
          </div>
          <div class="info-item">
            <div class="key">{{ $t('本金') }}</div>
            <div class="value">{{amount}} BUR</div>
          </div>
<!--          <div class="info-item" v-if="default_flag === false">-->
<!--            <div class="key">{{ $t('扣除本金') }}</div>-->
<!--            <div class="value">{{amount}} BUR</div>-->
<!--          </div>-->
        </div>
        <div class="tips" v-if="status === true">
          {{ $t('抱歉，您的聚合资产不足以完成兑换操作，请确认聚合资产是否足够后再继续操作。') }}
        </div>
        <div class="_but" @click="withdrawFixed">
          {{ $t('确定') }}
        </div>
        <div class="balance-tips">
          {{ $t('余额不足？') }}
          <router-link to="/bank/burExchange"> {{ $t('点击此处') }}</router-link>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {bankOrder, getInviteCode, getPackageRecord} from "../../api";
import {keepDecimal, levelPeriod} from "@/utils/tools";

export default {
  name: "storagePeriodicOrder",
  components: {TopNav},
  data() {
    return {
      state: 1,
      showExtract: false,
      page: 1,
      loading: false,
      finished: false,
      list: [],
      order_id: 0,
      polymerizeBalanceOf: 0,
      amount: 0,
      status: false,
      default_flag: true,
      current:0,
      total: 0
    }
  },
  created() {
    this.getAccount()
    this.getInitData()
  },
  methods: {
    async getAccount() {
      await this.$store.dispatch('bur/getAccount')
          .then(res => {
            if (res) {
              this.account = res
              console.log('account----', res)
            }
          })
    },
    async getInitData() {
      let that = this;

      this.$toast.loading({
        message: '',
        duration: 0,
      });

      await this.getAccount()
      that.polymerizeBalanceOf = await this.$store.dispatch('bur/getPolymerizeBalanceOf', that.account) //聚合资产
      console.log('polymerizeBalanceOf-------', that.polymerizeBalanceOf)
      that.$toast.clear()
    },
    async withdrawFixed() {
      let that = this;
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      await this.$store.dispatch('bur/withdrawFixed', { account:that.account, id:this.order_id })
          .then(res => {
            that.showExtract = false
            this.getInitData()
            if (this.state === 1) {
              this.selectState(this.state)
            }
            console.log('bur/withdrawFixed-------', res);
          })
          .catch(err => {
            setTimeout(() => {
              that.$toast(that.$t("操作失败"));
            }, 300)
            console.log(err);
          })
          .finally(() => {
            that.$toast.clear()
          })
    },
    onshowExtract(item) {
      this.showExtract = !this.showExtract
      this.order_id = item.order_id
      if (parseFloat(this.polymerizeBalanceOf) < parseFloat(item.amount)) {
        this.status = true
      } else {
        this.status = false
      }
      this.current = item.current
      this.amount = item.amount
      this.default_flag = item.default_flag
    },
    selectState(state) {
      console.log('selectState-------', state)
      this.state = state
      this.list = []
      this.page = 1
      this.total = 0
      this.finished = false
      this.loading = true
      this.onLoad()
    },
    onLoad() {
      this.bankOrder()
    },
    bankOrder() {

      let that = this
      bankOrder({ page: that.page, state: that.state, page_size: 30 }).then(({data}) => {
        console.log(data);

        if (data && data.data.data && data.code === 200) {
          that.total = data.data.total
          if (that.page === 1) {
            that.list = data.data.data
          } else {
            that.list = that.list.concat(data.data.data)
          }
          console.log('this.list', that.list)
          if (that.list.length < that.total) {
            that.page = that.page + 1
          } else {
            that.finished = true
          }
          that.loading = false
        }
      }).catch(()=>{
        that.loading = false
        that.finished = true //当接口404得加这个，不然也会一直调用刷新接口
      });
    },
  }
}
</script>

<style scoped lang="less">
.state-cont {
  display: flex;
  padding: 0.75rem 0 0.7rem;
  background: #151515;

  .state-item {
    flex: 1;
    font-size: 0.93rem;
    color: #987534;
    text-align: center;

    span {
      display: inline-block;
      padding-bottom: 0.3rem;
    }
  }

  .active {
    color: #E7BD71;
    border-bottom: 1px solid #E7BD71;
  }
}

.order-cont {

  .order-item {
    margin: 0.7rem 4vw 0.7rem 4vw;
    padding: 1.6rem 1.3rem;
    background: #1B1B1B;
    border-radius: 1rem;

    .info-row {
      overflow: hidden;
      font-size: 0.93rem;
      color: #6E6045;
      line-height: 2.14rem;
    }

    .info-left {
      float: left;
    }

    .info-right {
      float: right;
    }

    .line {
      margin: 0.8rem 0;
      height: 0.04rem;
      background: #262526;
    }

  }
}

._but {
  margin-top: 1rem;
  height: 2.86rem;
  line-height: 2.86rem;
  color: #000000;
  font-size: 1.14rem;
  text-align: center;
  background: linear-gradient(-90deg, #E8BD70 0%, #EDD185 100%);
  border-radius: 1.43rem;
}

.extract-cont {
  width: 85vw;
  max-height: 80vh;
  overflow-y: auto;
  padding: 1.2rem 1rem;
  box-sizing: border-box;
  background: #2D2D2D;
  border-radius: 1.07rem;

  .popup-title {
    text-align: center;
    color: #E7BD71;
    font-size: 1.14rem;
    margin-bottom: 1.8rem;

    .close-icon {
      float: right;
      position: relative;
      top: 0.3rem;
      width: 1.05rem;
    }
  }

  .tips {
    font-size: 0.86rem;
    color: #E64937;
    line-height: 1.07rem;
  }

  .info-cont {
    margin: 1rem 0;
    padding: 1.32rem;
    background: #1B1B1B;
    border-radius: 0.71rem;

    .info-item {
      overflow: hidden;
      padding: 0.5rem 0;

      .key {
        float: left;
        font-size: 0.93rem;
        color: #6E6045;
      }

      .value {
        float: right;
        font-size: 1.07rem;
        color: #E7BD71;
      }
    }
  }

  .balance-tips {
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 0.86rem;
    color: #625F5F;
    line-height: 1.5rem;

    a {
      color: #E7BD71;
    }
  }
}
</style>
